import React from 'react'
import { Form } from 'react-final-form'
import { SubmitButtonProps } from 'components/common/submitButton'
import { Trans, useTranslation } from 'react-i18next'
import CodeField from 'components/common/forms/codeField'

interface Props {
    subscription?: { [property: string]: boolean }
    onSubmit: (values: EmailValidationFormState) => void
    resendButton: (props: Partial<SubmitButtonProps<React.ElementType>>) => React.ReactNode
    children: (
        content: React.ReactNode,
        actions: Array<SubmitButtonProps<React.ElementType>>,
    ) => React.ReactNode
    inProgress: boolean
    initialValues: EmailValidationFormState
}

export interface EmailValidationFormState {
    code: string
}

interface EmailValidationFormErrors {
    code?: string
}

const EmailValidationForm: React.FC<Props> = ({
    subscription = {
        submitting: true,
        pristine: true,
    },
    onSubmit,
    children,
    inProgress,
    initialValues,
    resendButton,
}) => {
    const { t } = useTranslation()

    const validate = (values: EmailValidationFormState) => {
        const errors: EmailValidationFormErrors = {}

        if (!values.code) {
            errors.code = t('formValidation.code')
        } else if (values.code.length !== 6) {
            errors.code = t('formValidation.codeLength')
        }

        return errors
    }

    return (
        <Form
            initialValues={initialValues}
            subscription={subscription}
            // debug={console.log}
            onSubmit={onSubmit}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                    {children(
                        <>
                            <CodeField name="code" />
                            <div>{resendButton({})}</div>
                        </>,
                        [
                            {
                                type: 'submit',
                                loading: submitting || inProgress,
                                children: <Trans i18nKey="actions.continue" />,
                            },
                        ],
                    )}
                </form>
            )}
        </Form>
    )
}

export default EmailValidationForm
