import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { mutations } from 'core/graphql/mutations'
import actions from 'core/store/actions'
import { generateError } from 'core/graphql/error'

export const useEmailVerification = () => {
    const dispatch = useDispatch()
    const [inProgress, setInProgress] = useState(false)
    const [resendInProgress, setResendInProgress] = useState(false)

    const validateEmail = (code: string): Promise<boolean> => {
        setInProgress(true)
        return mutations
            .validateEmail(code)
            .then((isValidated) => {
                dispatch(actions.validateEmail(isValidated))
                return isValidated
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setInProgress(false)
            })
    }

    const resendCode = (): Promise<boolean> => {
        setResendInProgress(true)
        return mutations
            .resendCode()
            .then((hasResend) => {
                return hasResend
            })
            .catch((error) => {
                throw generateError(error)
            })
            .finally(() => {
                setResendInProgress(false)
            })
    }

    return {
        validateEmail,
        resendCode,
        inProgress,
        resendInProgress,
    }
}
