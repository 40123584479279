import React, { useEffect, useState } from 'react'
import withUser from 'containers/hoc/withUser'
import { graphql, navigate } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import withAuthentication, { WithAuthenticationProps } from 'containers/hoc/withAuthentication'
import Main from 'containers/common/main'
import EmailValidationView from 'components/views/account/emailValidationView'
import { useEmailVerification } from 'core/services/user/useEmailVerification'
import { useApiErrors } from 'core/services/api/useApiError'
import { useLogOut } from 'core/services/user/useLogOut'
import { useSnackbar } from 'notistack'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { EmailValidationFormState } from 'components/forms/account/emailValidationForm'
import { Container, Typography } from '@mui/material'

interface Props extends RouteComponentProps, WithAuthenticationProps {}

const EmailValidation: React.FC<Props> = ({ isLoading, user }) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    useEffect(() => {
        if (!isLoading && user?.hasEmailValidated) {
            navigate('/')
        }
    }, [isLoading, user])

    const { displayError } = useApiErrors()
    const { logOut } = useLogOut()
    const {
        validateEmail,
        inProgress: verifyEmailInProgress,
        resendCode,
        resendInProgress,
    } = useEmailVerification()

    const [resendDisabled, setResendDisabled] = useState(false)

    const handleEmailValidation = (values: EmailValidationFormState) => {
        validateEmail(values.code)
            .then((isValidated) => {
                if (isValidated) {
                    enqueueSnackbar(t('account.emailValidation.success'), {
                        variant: 'success',
                    })
                    navigate('/')
                } else {
                    enqueueSnackbar(t('account.emailValidation.failed'), {
                        variant: 'error',
                    })
                }
            })
            .catch((error) => displayError(error))
    }

    const handleSignOut = () => {
        logOut()
    }

    const handleResendCode = () => {
        resendCode()
            .then(() => {
                setResendDisabled(true)
                enqueueSnackbar(t('account.emailValidation.resendSuccess'), {
                    variant: 'success',
                })
            })
            .catch((error) => displayError(error))
    }

    return (
        <Main>
            <Container maxWidth="sm" sx={{ minHeight: 'calc(100vh - 64px)', paddingBottom: 4 }}>
                <Typography variant="h1" sx={{ marginBottom: 4 }}>
                    <Trans i18nKey="account.emailValidation.title" />
                </Typography>
                <EmailValidationView
                    loading={isLoading}
                    initialValues={{ code: '' }}
                    inProgress={verifyEmailInProgress}
                    onSubmit={handleEmailValidation}
                    onCancel={handleSignOut}
                    email={user?.email ?? ''}
                    onResend={handleResendCode}
                    resendInProgress={resendInProgress}
                    resendDisabled={resendDisabled}
                />
            </Container>
        </Main>
    )
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
export default withAuthentication(withUser(EmailValidation))
