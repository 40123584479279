import React from 'react'
import { Skeleton } from '@mui/material'

interface Props {
    loading: boolean
}

const SkeletonWrapper: React.FC<Props> = ({ loading, children }) => {
    if (loading) {
        return <Skeleton width="100%">{children}</Skeleton>
    } else {
        return <>{children}</>
    }
}

export default SkeletonWrapper
