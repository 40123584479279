import React from 'react'
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import SubmitButton from 'components/common/submitButton'
import { Trans } from 'gatsby-plugin-react-i18next'
import EmailValidationForm, { EmailValidationFormState } from 'components/forms/account/emailValidationForm'
import SkeletonWrapper from '../../common/skeletonWrapper'

interface Props {
    loading: boolean
    onSubmit: (values: EmailValidationFormState) => void
    inProgress: boolean
    resendInProgress: boolean
    resendDisabled: boolean
    initialValues: EmailValidationFormState
    email: string
    onCancel: () => void
    onResend: () => void
}

const EmailValidationView: React.FC<Props> = ({
    loading,
    email,
    onResend,
    resendInProgress,
    inProgress,
    onCancel,
    resendDisabled,
    ...formData
}) => {
    return (
        <Card sx={{ width: '100%' }}>
            <EmailValidationForm
                resendButton={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ marginRight: 1 }}>
                            <Trans i18nKey="account.emailValidation.resend" />
                        </Typography>
                        <SubmitButton
                            variant="contained"
                            onClick={onResend}
                            loading={resendInProgress}
                            disabled={inProgress || resendDisabled}
                        >
                            <Trans i18nKey="actions.resendCode" />
                        </SubmitButton>
                    </Box>
                )}
                inProgress={inProgress}
                {...formData}
            >
                {(content, actions) => (
                    <>
                        <CardContent>
                            <SkeletonWrapper loading={loading}>
                                <Typography>
                                    <Trans i18nKey="account.emailValidation.message" values={{ email }} />
                                </Typography>
                            </SkeletonWrapper>
                            {content}
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <SubmitButton variant="contained" onClick={onCancel}>
                                <Trans i18nKey="actions.previous" />
                            </SubmitButton>
                            {actions.map((action, index) => (
                                <SubmitButton
                                    sx={{ width: 160, marginLeft: 1 }}
                                    key={index}
                                    variant="contained"
                                    color="secondary"
                                    {...action}
                                />
                            ))}
                        </CardActions>
                    </>
                )}
            </EmailValidationForm>
        </Card>
    )
}

export default EmailValidationView
