import * as React from 'react'
import { ComponentType, useEffect } from 'react'
import { navigate } from 'gatsby'
import { WithAuthenticationProps } from './withAuthentication'

const withUser = (ComposedComponent: ComponentType<WithAuthenticationProps>) => {
    const WithUser: React.FC<WithAuthenticationProps> = (props) => {
        const { user, isLoading } = props

        useEffect(() => {
            if (!isLoading && user === undefined) {
                navigate('/')
            }
        }, [isLoading, user])

        return <ComposedComponent {...props} />
    }
    return WithUser
}

export default withUser
