import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { UserModel } from 'core/models/userModel'

interface InputProps {}

export interface WithAuthenticationProps {
    user?: UserModel
    isLoading: boolean
}

const withAuthentication = (ComposedComponent: React.ComponentType<WithAuthenticationProps>) => {
    const WithAuthentication: React.FC<InputProps> = (props) => {
        const { user, init } = useSelector(({ app }: ApplicationState) => ({
            user: app.user,
            init: app.init,
        }))
        return <ComposedComponent user={user} isLoading={!init} {...props} />
    }
    return WithAuthentication
}

export default withAuthentication
